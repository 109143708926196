<template>
  <div class="services" id="services">
    <div class="ui grid container faq">
      <div class="intro-content">
        <cashin />
        <cashout />
        <buyload />
        <banktransfers />
        <fundtransfer />
        <paybills />
        <payqr />
        <div class="buttons">
          <a href="/faq/partnermerchant" class="yellow">BACK</a>
          <a href="/faq/complaints" class="white">NEXT</a>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'services',
  components: {
    'cashin': () => import('./services/CashIn.vue'),
    'cashout': () => import('./services/CashOut.vue'),
    'buyload': () => import('./services/BuyLoad.vue'),
    'banktransfers': () => import('./services/BankTransfers.vue'),
    'fundtransfer': () => import('./services/FundTransfer.vue'),
    'paybills': () => import('./services/PayBills.vue'),
    'payqr': () => import('./services/PayQR.vue')
  },
  mounted () {
    var VueScrollTo = require('vue-scrollto');
    var element = location.hash

    var options = {
      easing: 'ease-in',
      offset: -85,
      force: true,
      x: false,
      y: true
    }

    if (location.hash) {
      this.$nextTick(() => {
        setTimeout(function() {
          console.log('faq')
          VueScrollTo.scrollTo(element, options)
        }, 200)
      })
    }
  }
}
</script>

<style scoped lang='scss'>
</style>
